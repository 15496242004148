import {
  Grid,
  Typography,
  Button,
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Image, { StaticImageData } from "next/image";
import React, { useEffect, useState } from "react";
import { appLink } from "../utils/Constants";
import GromoLogo from "../public/assets/images/home/GromoLogo.svg";
import playStoreBlack from "../public/assets/images/home/playStoreBlack.svg";
// import appStoreBlack from "../public/assets/images/home/appStoreBlack.svg";
import Icon_Facebook from "../public/assets/images/home/Icon_Facebook.webp";
import Icon_WhatsApp from "../public/assets/images/home/Icon_WhatsApp.svg";
import Icon_Instagram from "../public/assets/images/home/Icon_Instagram.webp";
import Icon_LinkedIn from "../public/assets/images/home/Icon_LinkedIn.webp";
import Icon_Youtube from "../public/assets/images/home/Icon_Youtube.webp";
import Icon_Telegram from "../public/assets/images/home/Icon_Telegram.webp";
import { PLAYSTORE_BLACK } from "../utils/ImageExport";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRouter } from "next/router";
import Link from "next/link";

function AppFooter() {
  const [imgSrc, setImgSrc] = useState<StaticImageData>(PLAYSTORE_BLACK);
  const [moreAbout, setMoreAbout] = useState<boolean>(false);
  useEffect(() => {
    // if (Utils.getOS()?.toLocaleLowerCase() == "android") {
    setImgSrc(PLAYSTORE_BLACK);
    // }
    // else if (Utils.getOS()?.toLocaleLowerCase() == "ios") {
    //   setImgSrc(APPSTORE_BLACK);
    // }
  }, []);
  const router = useRouter();

  const redirectToProduct = (redirectUrl: string) => {
    localStorage.setItem("hash", redirectUrl);
    setTimeout(() => {
      router.push(`/#our-products`);
      setTimeout(() => {
        router.push(`/#${redirectUrl}`);
      }, 900);
    }, 10);
  };
  return (
    <Grid
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#212736",
        color: "white",
        padding: "3%",
      }}
    >
      {/* FOOTER WRAPPER DIV  */}
      <Grid
        sx={{
          height: "90%",
          width: { xs: "95%", md: "90%" },
          display: "flex",
          flexDirection: "column",
          maxWidth: "1440px",
          margin: "auto",
        }}
      >
        {/* FOOTER TOP PART  */}
        <Grid
          sx={{
            display: { xs: "grid", md: "flex" },
            height: "fit-content",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          {/* FOOTER COLUMN-1st */}
          <Grid
            sx={{
              display: { xs: "flex", md: "flex" },
              flexDirection: { xs: "column" },
              width: { xs: "100%", md: "35%" },
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: { xs: "center", md: "normal" },
            }}
          >
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image
                src={GromoLogo}
                alt="logo"
                width={120}
                height={40}
                style={{
                  margin: "10px 0 5px 0",
                }}
                loading="lazy"
              />
            </Grid>

            <Grid
              sx={{
                display: { xs: "flex", md: "block" },
                justifyContent: { xs: "space-between", md: "center" },
                alignItems: "center",
              }}
            >
              <Grid padding="4px">
                <Typography
                  sx={{
                    fontSize: { xs: "15px", md: "18px" },
                    maxWidth: "1440px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Sell financial products & earn <span>₹1 Lakh/month</span>
                </Typography>
              </Grid>

              {/* BUTTON WRAPPER */}
              <Grid
                sx={{
                  margin: "10px 0",
                  padding: "0",
                  display: { xs: "block", md: "flex" },
                }}
              >
                <Button
                  href={appLink}
                  aria-label="appStoreButton"
                  sx={{
                    fontSize: "24px",
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <Image
                    width={140}
                    height={140}
                    src={playStoreBlack}
                    alt="appStoreButton"
                    loading="lazy"
                  />
                </Button>

                <Button
                  href={appLink}
                  aria-label="download link"
                  sx={{
                    display: { xs: "block", md: "none" },
                    fontSize: "24px",
                  }}
                >
                  <Image
                    src={imgSrc!}
                    width={140}
                    height={140}
                    alt="download link"
                    style={{
                      objectFit: "contain",
                    }}
                    loading="lazy"
                  />
                </Button>
              </Grid>
            </Grid>

            <Grid
              sx={{
                display: { xs: "flex", md: "flex" },
                justifyContent: { xs: "space-between", md: "flex-start" },
                alignItems: "center",
                margin: { xs: "0", md: "10px 0" },
              }}
            >
              {[
                {
                  name: "linkedIn",
                  img: Icon_LinkedIn,
                  link: "https://www.linkedin.com/company/gromofintech/",
                },
                {
                  name: "youtube",
                  img: Icon_Youtube,
                  link: "https://www.youtube.com/channel/UCn5RV1XAm2Fpq5CRn_aw21A?sub_confirmation=1",
                },
                {
                  name: "facebook",
                  img: Icon_Facebook,
                  link: "https://www.facebook.com/gromofintech/",
                },
                {
                  name: "twitter",
                  img: Icon_WhatsApp,
                  link: "https://whatsapp.com/channel/0029Va9ASKX0gcfGBCh00d0z",
                },
                {
                  name: "instagram",
                  img: Icon_Instagram,
                  link: "https://www.instagram.com/gromo.fintech/",
                },
                {
                  name: "telegram",
                  img: Icon_Telegram,
                  link: "https://t.me/GroMoFin/",
                },
              ].map((item) => {
                return (
                  <Link
                    href={item.link}
                    target={"_blank"}
                    key={item.name}
                    rel="preload"
                  >
                    <IconButton aria-label="social media icons">
                      <Image
                        src={item.img}
                        style={{
                          margin: "0px 5px",
                        }}
                        width={40}
                        height={40}
                        loading="lazy"
                        alt={item.name}
                      />
                    </IconButton>
                  </Link>
                );
              })}
            </Grid>
          </Grid>

          <Grid
            sx={{
              width: { xs: "100%", md: "45%" },
              display: "flex",
              justifyContent: "right",
            }}
          >
            {/* FOOTER COLUMN-2nd */}
            <Grid
              sx={{
                width: { xs: "100%", md: "45%" },
                paddingBottom: "10px",
                borderBottom: "1px solid #212736",
              }}
            >
              <Typography
                variant="body1"
                margin="10px 0"
                color="#9EA2AE"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                Products
              </Typography>
              {[
                { title: "Credit Card", redirect: "CreditCard" },
                { title: "Savings Account", redirect: "SavingAccount" },
                { title: "Demat Account", redirect: "DematAccount" },
                { title: "Loan", redirect: "Loan" },
                { title: "Line of Credit", redirect: "Credit" },
                { title: "Investment", redirect: "Investment" },
                { title: "Sell Insurance", redirect: "" },
              ].map((text, i) => {
                return (
                  <Typography
                    variant="body1"
                    key={i}
                    sx={{
                      display: { xs: "block", md: "block" },
                      cursor: "pointer",
                      fontSize: { xs: "14px", md: "16px" },
                      fontWeight: "500",
                      lineHeight: "20px",
                      padding: "6px 0",
                    }}
                    onClick={() => {
                      if (text.redirect) {
                        redirectToProduct(text.redirect);
                      }
                    }}
                  >
                    {text.title}
                  </Typography>
                );
              })}
            </Grid>

            {/* FOOTER COLUMN-3rd */}
            <Grid
              sx={{
                width: { xs: "100%", md: "45%" },
                borderBottom: "1px solid #212736",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="body1"
                margin="10px 0"
                color="#9EA2AE"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                Company
              </Typography>

              {[
                {
                  text: "Academy",
                  path: "https://gromo.co.in/MFtc",
                },
                {
                  text: "Partner with us",
                  path: appLink,
                },
                {
                  text: "Blog",
                  path: "https://gromo.in/blog/",
                },
              ].map((obj, i) => {
                return (
                  <Link href={obj.path} key={i}>
                    <Typography
                      variant="body1"
                      sx={{
                        cursor: "pointer",
                        fontSize: { xs: "14px", md: "16px" },
                        fontWeight: "500",
                        lineHeight: "20px",
                        padding: "6px 0",
                      }}
                    >
                      {obj.text}
                    </Typography>
                  </Link>
                );
              })}
              <Link href="/about-us">
                <Typography
                  variant="body1"
                  sx={{
                    cursor: "pointer",
                    fontSize: { xs: "14px", md: "16px" },
                    fontWeight: "500",
                    lineHeight: "20px",
                    padding: "6px 0",
                  }}
                >
                  About us
                </Typography>
              </Link>

              <Link href="/careers">
                <Typography
                  variant="body1"
                  sx={{
                    display: { cursor: "pointer" },
                    fontSize: { xs: "14px", md: "16px" },
                    fontWeight: "500",
                    lineHeight: "20px",
                    padding: "6px 0",
                  }}
                >
                  Careers
                  <span
                    style={{
                      border: "1px solid black",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      background: "#8DD993",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "14px",
                      cursor: "pointer",
                      margin: "0px 5px",
                    }}
                  >
                    Hiring!
                  </span>
                </Typography>
              </Link>
            </Grid>
          </Grid>

          {/* FOOTER COLUMN-4th */}
          <Grid
            sx={{
              width: { xs: "100%", md: "20%" },
              borderBottom: "1px solid #212736",
              paddingBottom: "10px",
            }}
          >
            <Typography
              variant="body2"
              margin="10px 0"
              color="#9EA2AE"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              Contact Us
            </Typography>
            <Grid
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              <Typography
                sx={{
                  padding: "6px 0",
                }}
              >
                <Link
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "white",
                  }}
                  href="tel:+918062159334"
                >
                  +91 8062159334
                </Link>
              </Typography>

              <Typography
                sx={{
                  padding: "6px 0",
                }}
              >
                <Link
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "white",
                    padding: "6px 0",
                  }}
                  href="mailto:contact@gromo.in"
                >
                  contact@gromo.in
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* PRIVACY POLICY PART */}
        <hr
          style={{ background: "#393F50", color: "#393F50", height: "2px" }}
        />
        <Grid
          sx={{ display: { xs: "block", md: "flex" } }}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px 8px 8px 8px",
            width: "100%",
          }}
        >
          <Grid
            sx={{ display: { xs: "grid", md: "flex" } }}
            style={{
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {[
              {
                path: "/privacy-policy",
                text: "Privacy policy",
              },
              {
                path: "/term-condition",
                text: "Terms & Conditions",
              },
              {
                path: "#",
                text: "Cancellation policy",
              },
              {
                path: "/data-storage-policy",
                text: "Data Storage Policy",
              },
            ].map((obj, i) => {
              return (
                <Link href={obj.path} key={i}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                      fontWeight: "500",
                      lineHeight: "20px",
                      margin: { xs: "10px 0", md: "10px 8px" },
                    }}
                  >
                    {obj.text}
                  </Typography>
                </Link>
              );
            })}
          </Grid>
          <Typography variant="body2" color="#9EA2AE" marginTop={2}>
            © 2024 GroMo. All rights reserved.
          </Typography>
        </Grid>

        {/* FOOTER-DETAILS PART  */}
        <Grid width="90%">
          <Box
            style={{
              background: "transparent",
              padding: 0,
            }}
          >
            <Accordion
              style={{
                backgroundColor: "#212736",
                color: "white",
                padding: 0,
              }}
              elevation={0}
            >
              <AccordionSummary>
                <Typography onClick={() => setMoreAbout(!moreAbout)}>
                  More About GroMo
                  {moreAbout ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <Grid style={{ margin: "10px 0" }}>
                    <Typography variant="body1" fontWeight="bold">
                      About GroMo
                    </Typography>
                    <Typography variant="body1">
                      Do you want to earn money online without investment?
                      Download GroMo to earn extra income from home today!
                    </Typography>
                  </Grid>

                  <Grid style={{ margin: "10px 0" }}>
                    <Typography variant="body1" fontWeight="bold">
                      What is GroMo ?
                    </Typography>
                    {[
                      {
                        text: `GroMo is the best money-earning app that will help you in
                          starting a business without any investment. Become a GroMo
                          partner by downloading the GroMo app from Play Store and
                          sell financial products from different brands to customers
                          and earn over ₹1,00,000 every month.`,
                      },
                      {
                        text: `You can attend free training, learn how to sell financial
                          products to customers and start earning.`,
                      },
                    ].map((item, i) => {
                      return (
                        <Typography
                          variant="body1"
                          style={{ margin: "10px 0" }}
                          key={i}
                        >
                          {item.text}
                        </Typography>
                      );
                    })}
                  </Grid>

                  <Grid style={{ margin: "10px 0" }}>
                    <Typography variant="body1" fontWeight="bold">
                      Who can become a GroMo Partner ?
                    </Typography>
                    {[
                      {
                        text: `You can be a working professional, a businessman, a
                          student or a housewife - our app is a one-stop solution
                          for anybody looking to learn and earn money by selling
                          financial products. Other than your primary source of
                          income, you can easily earn extra money from home by
                          joining GroMo.`,
                      },
                      {
                        text: `GroMo is one of the best side income sources where you can
                          engage customers by sharing content with them and getting
                          the financial product activated.`,
                      },
                    ].map((item, i) => {
                      return (
                        <Typography
                          variant="body1"
                          style={{ margin: "10px 0" }}
                          key={i}
                        >
                          {item.text}
                        </Typography>
                      );
                    })}
                  </Grid>

                  <Grid style={{ margin: "10px 0" }}>
                    <Typography variant="body1" fontWeight="bold">
                      What are the benefits of working with GroMo?
                    </Typography>
                    {[
                      {
                        title: "Zero Investment business",
                        content: "Start your own business with 0 investment",
                      },
                      {
                        title: "Work from homes",
                        content:
                          "Get one of the best at-home job opportunities",
                      },
                      {
                        title: "Quick payout",
                        content: "Shortest payout cycle in the market",
                      },
                      {
                        title: "Side income source",
                        content: "One of the best extra income sources",
                      },
                      {
                        title: "Great customer service",
                        content:
                          "Grow your business with help from our customer support team",
                      },
                    ].map((item, i) => {
                      return (
                        <Typography variant="body1" key={i}>
                          {" "}
                          {i + 1} <b> {item.title} </b>: {item.content}{" "}
                        </Typography>
                      );
                    })}
                  </Grid>

                  <Grid style={{ margin: "10px 0" }}>
                    <Typography variant="body1" fontWeight="bold">
                      What financial brands can you sell ?
                    </Typography>
                    <Typography variant="body1" style={{ margin: "10px 0" }}>
                      You can start your online business @0 investment with us
                      today. Sell insurance and financial products from next-gen
                      brands like Axis bank, Kotak 811 bank, Upstox, Paytm
                      Money, Freecharge, Bajaj Finserv, etc. and earn extra
                      income online without investment.
                    </Typography>
                    <Typography variant="body1" style={{ margin: "10px 0" }}>
                      You can sell financial products from the following brand
                      categories:
                    </Typography>
                    <ul>
                      {[
                        "Savings accounts",
                        "Insurance",
                        "Credit cards",
                        "Demat accounts",
                        "Credit accounts",
                        "Loan",
                        "Investments",
                      ].map((text, i) => {
                        return (
                          <li key={i} onClick={() => router.push("/product")}>
                            {text}
                          </li>
                        );
                      })}
                    </ul>
                    <Typography variant="body1" style={{ margin: "10px 0" }}>
                      {
                        "...and many other fantastic financial products with our at-home money earning business. Like what we do? Great, there's more!"
                      }
                    </Typography>
                  </Grid>

                  <Grid style={{ margin: "10px 0" }}>
                    <Typography variant="body1" fontWeight="bold">
                      What are the features of GroMo ?
                    </Typography>
                    {[
                      {
                        text: `GroMo is a great platform to sell financial products.
                         Trust us, you won't find a better way to earn extra online
                         without investment. Your customers will be happy to get
                         access to multiple financial products with you and you
                         will be happy to earn more income from home, all in one
                         app.`,
                      },
                      {
                        text: `You can even build a team through referrals and boost your
                          earning with our app to make money. Here's is a list of
                          GroMo features you would love:`,
                      },
                    ].map((item, i) => {
                      return (
                        <Typography
                          variant="body1"
                          style={{ margin: "10px 0" }}
                          key={i}
                        >
                          {item.text}
                        </Typography>
                      );
                    })}
                    <Grid style={{ margin: "10px 0" }}>
                      {[
                        {
                          title: "Get educated",
                          content:
                            "Upskill with our free online courses and webinars and become a certified financial advisor while still doing home based work.",
                        },
                        {
                          title: "Manage customers",
                          content:
                            "Manage your customers and track their status in your GroMo App. Moreover, you can set up reminders to follow up with your customers.",
                        },
                        {
                          title: "Engage your customers",
                          content:
                            "Keep your audience engaged with personalized content, website and visiting cards to sell financial products.",
                        },
                        {
                          title: "Refer and earn",
                          content:
                            "Take full advantage of our fantastic referral policy and refer people in your network to GroMo on a regular basis.",
                        },
                        {
                          title: "Track your earnings",
                          content:
                            " Use your in-app wallet to keep track of your earnings and transfer money to your bank account.",
                        },
                        {
                          title: "Data security",
                          content:
                            "Securely maintain customer data in your financial app without worrying about any data safety issues.",
                        },
                      ].map((item, i) => {
                        return (
                          <Typography variant="body1" key={i}>
                            {i + 1} <b>{item.title}</b> : {item.content}
                          </Typography>
                        );
                      })}
                    </Grid>

                    <Typography variant="body1" style={{ margin: "10px 0" }}>
                      Get ready to eam money without investment with GroMo.
                      Download our financial app today
                      <Link
                        style={{
                          color: "#4D71D0",
                          margin: "0px 5px",
                          cursor: "pointer",
                        }}
                        href={appLink}
                      >
                        Google Play Store
                      </Link>
                      start your job from home.
                    </Typography>
                  </Grid>
                </>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AppFooter;
